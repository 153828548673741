import { levels } from "@/data/serviceLevel";
import he from "he";

export const getServiceLevels = (slug: string) => {
  const { level } = levels.find(
    (item: { level: string; slug: string }) => item.slug === slug
  ) || { level: "", slug: "" };
  return level;
};

export const decodeHtml = (html: string) => {
  return he?.decode(html);
};

export function capitalizeFirstLetter(string = "") {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

export const BANNER_PAGES = [
  { url: "/", Pages: "Home Page" },
  { url: "/services/", Pages: "Service Level1" },
  { url: "/services/", Pages: "Service Level2" },
  { url: "/about-us", Pages: "About Us" },
  { url: "/resources/case-studies", Pages: "Case Study" },
  { url: "/resources/blog", Pages: "Blogs" },
  { url: "/resources/insights", Pages: "Insights" },
  { url: "/resources/whitepapers", Pages: "Whitepaper" },
  { url: "/industries", Pages: "Industry" },
  { url: "/contact-us", Pages: "contact Us" },
];

export const functionalCookie: any = ["cubet-cookie"];
export const imageLocalization: any = {
  AFRICA: "AfricaImage",
  IN: "IndiaImage",
  MIDDLEEAST: "MiddleEastImage",
  NORDIC: "NordicImage",
  UK: "UKImage",
  US: "USImage",
};
export const countryLocalization: any = {
  Alabama: "US",
  Alaska: "US",
  Arizona: "US",
  Arkansas: "US",
  California: "US",
  Colorado: "US",
  Connecticut: "US",
  Delaware: "US",
  Florida: "US",
  Hawaii: "US",
  Idaho: "US",
  Illinois: "US",
  Indiana: "US",
  Iowa: "US",
  Kansas: "US",
  Kentucky: "US",
  Louisiana: "US",
  Maine: "US",
  Maryland: "US",
  Massachusetts: "US",
  Michigan: "US",
  Minnesota: "US",
  Mississippi: "US",
  Missouri: "US",
  Montana: "US",
  Nebraska: "US",
  Nevada: "US",
  "New Hampshire": "US",
  "New Jersey": "US",
  "New Mexico": "US",
  "New York": "US",
  "North Carolina": "US",
  "North Dakota": "US",
  Ohio: "US",
  Oklahoma: "US",
  Oregon: "US",
  Pennsylvania: "US",
  "Rhode Island": "US",
  "South Carolina": "US",
  "South Dakota": "US",
  Tennessee: "US",
  Texas: "US",
  Utah: "US",
  Vermont: "US",
  Virginia: "US",
  Washington: "US",
  "West Virginia": "US",
  Wisconsin: "US",
  Wyoming: "US",
  Canada: "US",
  Alberta: "US",
  "British Columbia": "US",
  Manitoba: "US",
  "New Brunswick": "US",
  "Newfoundland and Labrador": "US",
  "Nova Scotia": "US",
  Ontario: "US",
  "Prince Edward Island": "US",
  Quebec: "US",
  Saskatchewan: "US",
  "Northwest Territories": "US",
  Nunavut: "US",
  Yukon: "US",
  "The Bahamas": "US",
  Belize: "US",
  "Costa Rica": "US",
  Cuba: "US",
  "El Salvador": "US",
  Guatemala: "US",
  Haiti: "US",
  Honduras: "US",
  Jamaica: "US",
  Mexico: "US",
  Nicaragua: "US",
  Panama: "US",
  Colombia: "US",
  Ecuador: "US",
  Peru: "US",
  "United States of America": "US",
  "Turks and Caicos Islands": "US",
  Chile: "US",
  "French Polynesia": "US",
  England: "UK",
  Scotland: "UK",
  Wales: "UK",
  "Northern Ireland": "UK",
  Greenland: "UK",
  "Akrotiri and Dhekelia": "UK",
  "United Kingdom": "UK",
  India: "IN",
  Denmark: "NORDIC",
  Finland: "NORDIC",
  Iceland: "NORDIC",
  Norway: "NORDIC",
  Sweden: "NORDIC",
  "Faroe Islands": "NORDIC",
  Åland: "NORDIC",
  Svalbard: "NORDIC",
  "Jan Mayen": "NORDIC",
  "Bouvet Island": "NORDIC",
  Poland: "NORDIC",
  France: "NORDIC",
  Hungary: "NORDIC",
  Germany: "NORDIC",
  Ukraine: "NORDIC",
  Algeria: "AFRICA",
  Angola: "AFRICA",
  Benin: "AFRICA",
  Botswana: "AFRICA",
  "Burkina Faso": "AFRICA",
  Burundi: "AFRICA",
  "Cabo Verde": "AFRICA",
  Cameroon: "AFRICA",
  "Central African Republic": "AFRICA",
  Chad: "AFRICA",
  Comoros: "AFRICA",
  "Democratic Republic of the Congo": "AFRICA",
  "Republic of the Congo": "AFRICA",
  Djibouti: "AFRICA",
  "Equatorial Guinea": "AFRICA",
  Eritrea: "AFRICA",
  Eswatini: "AFRICA",
  Ethiopia: "AFRICA",
  Gabon: "AFRICA",
  "The Gambia": "AFRICA",
  Ghana: "AFRICA",
  Guinea: "AFRICA",
  "Guinea-Bissau": "AFRICA",
  "Ivory Coast (Côte d'Ivoire)": "AFRICA",
  Kenya: "AFRICA",
  Lesotho: "AFRICA",
  Liberia: "AFRICA",
  Libya: "AFRICA",
  Madagascar: "AFRICA",
  Malawi: "AFRICA",
  Mali: "AFRICA",
  Mauritania: "AFRICA",
  Mauritius: "AFRICA",
  Morocco: "AFRICA",
  Mozambique: "AFRICA",
  Namibia: "AFRICA",
  Niger: "AFRICA",
  Nigeria: "AFRICA",
  Rwanda: "AFRICA",
  "Sao Tome and Principe": "AFRICA",
  Senegal: "AFRICA",
  Seychelles: "AFRICA",
  "Sierra Leone": "AFRICA",
  Somalia: "AFRICA",
  "South Africa": "AFRICA",
  "South Sudan": "AFRICA",
  Sudan: "AFRICA",
  Tanzania: "AFRICA",
  Togo: "AFRICA",
  Tunisia: "AFRICA",
  Uganda: "AFRICA",
  Zambia: "AFRICA",
  Zimbabwe: "AFRICA",
  Réunion: "AFRICA",
  "Western Sahara": "AFRICA",
  Mayotte: "AFRICA",
  "Saint Helena": "AFRICA",
  Afghanistan: "MIDDLEEAST",
  Bahrain: "MIDDLEEAST",
  Iran: "MIDDLEEAST",
  Iraq: "MIDDLEEAST",
  Israel: "MIDDLEEAST",
  Jordan: "MIDDLEEAST",
  Kuwait: "MIDDLEEAST",
  Lebanon: "MIDDLEEAST",
  Oman: "MIDDLEEAST",
  Palestine: "MIDDLEEAST",
  Qatar: "MIDDLEEAST",
  "Saudi Arabia": "MIDDLEEAST",
  Syria: "MIDDLEEAST",
  Turkey: "MIDDLEEAST",
  "United Arab Emirates": "MIDDLEEAST",
  Yemen: "MIDDLEEAST",
  "Abu Dhabi": "MIDDLEEAST",
  Dubai: "MIDDLEEAST",
  Sharjah: "MIDDLEEAST",
  Cyprus: "MIDDLEEAST",
  Egypt: "MIDDLEEAST",
  Azerbaijan: "MIDDLEEAST",
  Georgia: "MIDDLEEAST",
};

export const debounce = (func: Function, delay: number) => {
  let timer: NodeJS.Timeout;
  return (...args: any[]) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};
